import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Layout from "../../components/layout";
import Seo from "../../components/seo";
import ContactPerson from "../../components/contactPerson";
import { GatsbyImage } from "gatsby-plugin-image";
import Hero from "../../components/hero";
import { CaseList } from "../../components/caseList";

import doodle from "../../assets/images/services/design.svg";

const DesignKreation = () => {
  const images = useStaticQuery(graphql`
    {
      hero: file(relativePath: { eq: "services/hero-design.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
      fabio: file(relativePath: { eq: "profiles/fabio-baser.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
        }
      }
    }
  `);

  return (
    <Layout lang="de" langPath="service.design.link">
      <Seo
        title="Design und Kreation – Monday Consulting"
        description="Unsere kreativen Experten lassen Ihre Webseite in neuem Glanz erstrahlen. Wir entwickeln einen Look, der nicht nur optisch, sondern auch durch die Methode dahinter überzeugt."
        keywords="Design, Kreation, Atomic, Multi Channel, Storybook, Living Styleguide"
      />
      <div className="parallax-translate bg-shape-vertigo"></div>
      <Hero
        headline="Perfekter Glanz über alle Kanäle hinweg"
        subline="Wir gestalten Ihr Design immer mit einem Blick auf die Programmierung."
        section="Design & Kreation"
      >
        <GatsbyImage
          image={images.hero.childImageSharp.gatsbyImageData}
          className="hero-image"
          alt="Design & Kreation"
        />
      </Hero>
      <div className="container mt-3 mb-5 mb-md-6">
        <div className="row align-items-start">
          <div className="col-md-6 d-none d-sm-block">
            <img
              className="w-75"
              alt="Kreation und Technik, die sich ergänzen"
              src={doodle}
            />
          </div>
          <div className="col-md-6">
            <h2>Kreation und Technik, die sich ergänzen</h2>
            <p>
              Wir lassen Ihre Webseite in neuem Glanz erstrahlen. Dabei spielt
              es für uns keine Rolle, ob Sie sich für ein neues Design
              entscheiden, ein bestehendes Corporate Design für das Web
              adaptieren oder weitere Kanäle ausspielen möchten. Wir helfen
              Ihnen bei allen Aufgaben rund um Design und Kreation.
            </p>
            <p>
              Unsere kreativen Experten entwickeln für Sie einen (neuen) Look,
              der nicht nur optisch, sondern auch durch die Methode dahinter
              überzeugt. Wir setzen auf ein Atomic Design, das es uns
              ermöglicht, einen Baukasten aus allen Elementen zu erstellen. Auf
              diese Weise können wir schnell und unkompliziert neue
              Anforderungen nach Ihren Gestaltungsrichtlinien umzusetzen.
            </p>
            <p>
              Um die Zusammenarbeit zu erleichtern, erarbeiten unsere Designer
              und Entwickler gemeinsam einen Living Styleguide auf Basis von
              Storybook. Hier werden alle Elemente gesammelt und exemplarisch
              miteinander verknüpft, um so flexibel zur Übernahme in Ihr System
              bereitzustehen.
            </p>
          </div>
        </div>
      </div>

      <div className="container mb-md-0">
        <div
          className="row vertical-spacing mb-3 reveal"
          data-spacing-bottom="details"
        >
          <h2 className="col-12 text-center">Design & Kreation im Detail</h2>
        </div>
        <div className="row align-items-start">
          <div
            className="reveal col-11 col-md-5 mb-4 mb-md-6 bg-serviceicon-multichannel bg-position-tl bg-size-50 vertical-offset"
            id="details"
            data-offset="50%"
          >
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                &nbsp;
              </span>
              Multi Channel
            </h3>
            <p>
              Egal ob Mobile, Tablet oder Desktop – unser Design berücksichtigt
              alle kanalspezifischen Herausforderungen, um unabhängig vom Gerät
              maximale Flexibilität zu bieten.
            </p>
          </div>

          <div className="reveal col-11 col-md-5 offset-1 pb-4 pb-md-0 mb-4 mb-md-6 bg-serviceicon-atomicdesign bg-position-tl bg-size-50">
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                &nbsp;
              </span>
              Atomic Design
            </h3>
            <p>
              Wir stellen alle Komponenten Ihres Designs heraus und erschaffen
              einen Fundus aus allen Elementen. Auf diese Weise können wir
              jederzeit auf die einzelnen Bestandteile zurückgreifen und diese
              bei neuen Anforderungen leicht miteinander kombinieren, um so den
              Aufwand für z.B. Landingpages zu minimieren.
            </p>
          </div>

          <div
            className="reveal col-11 col-md-5 mb-4 mb-md-6 offset-md-1 bg-serviceicon-styleguide bg-position-tl bg-size-50 vertical-offset"
            data-offset="50%"
          >
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                &nbsp;
              </span>
              Living Styleguide
            </h3>
            <p>
              Die zentrale Basis eines jeden Projekts stellt der Living
              Styleguide dar, den wir mittels Storybook im Zusammenspiel mit den
              Entwicklern erstellen und weiterentwickeln. So sind Sie in der
              Lage, alle Elemente und fertige Seitentypen bereits vor der
              Entwicklung zu prüfen.
            </p>
          </div>

          <div className="reveal col-11 col-md-5 pb-4 pb-md-0 mb-4 offset-1 bg-serviceicon-design-migration bg-position-tl bg-size-50">
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                &nbsp;
              </span>
              Übernahme bestehender Designs
            </h3>
            <p>
              Sie arbeiten bereits mit einer Kreativagentur zusammen oder
              besitzen ein ausgearbeitetes Corporate Design, welches übernommen
              und erweitert werden soll? Kein Problem – unsere eingespielten
              Prozesse sind für alle Situationen bestens aufgestellt.
            </p>
          </div>
        </div>
      </div>

      <div
        className="content-container vertical-spacing bg-monday-skyblue bg-pattern-vertigo text-color-white text-center parallax-background"
        data-spacing-bottom="cases"
      >
        <div className="container pt-4 pt-md-5 pb-3 reveal">
          <div className="row mb-3">
            <div className="col-12 d-flex flex-column align-items-center">
              <h3 className="mb-3">Ausgewählte Referenzen</h3>
            </div>
          </div>
        </div>
      </div>
      <div
        id="cases"
        className="container vertical-offset reveal mb-md-6 mb-5"
        data-offset="50%"
      >
        <CaseList name="swp,gdv" />
      </div>

      <ContactPerson
        headline="Haben wir Ihr Interesse geweckt?"
        text="Sie stehen in Ihrem Unternehmen vor einer ähnlichen Herausforderung? Teilen Sie uns noch heute Ihre Wünsche und Anforderungen mit. Unsere Experten beraten Sie gerne."
        contactEmail="frontend@monday-consulting.com"
        contactPhone="+49 40 22 86 842 0"
        contactName="Fabio Baser, Unit-Leiter Frontend"
      >
        <GatsbyImage
          image={images.fabio.childImageSharp.gatsbyImageData}
          alt="Fabio Baser"
        />
      </ContactPerson>
    </Layout>
  );
};

export default DesignKreation;
